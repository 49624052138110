<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
             <el-breadcrumb-item>商家信息</el-breadcrumb-item>
              <el-breadcrumb-item>商家员工</el-breadcrumb-item>
            <el-breadcrumb-item>添加/编辑</el-breadcrumb-item>

          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form
        class="form-container"
        ref="form"
        :model="form"
        label-width="140px"
      >
       
                     <el-form-item
          label="员工姓名"
          :error="$v.form.staffname.$error ? '请输入员工姓名' : ''"
        >
          <el-input v-model="form.staffname"></el-input>
        </el-form-item>
                      <el-form-item
          label="员工手机号"
          :error="$v.form.staffphone.$error ? '请输入员工手机号' : ''"
        >
          <el-input v-model="form.staffphone"></el-input>
        </el-form-item>
                      <el-form-item
          label="关联会员账号"
          :error="$v.form.useraccount.$error ? '请输入关联会员账号' : ''"
        >
          <el-input v-model="form.useraccount"></el-input>
        </el-form-item>



        <el-form-item
          label="员工头像"
          :error="$v.form.staffphoto.$error ? '请上传员工头像' : ''"
        >
          <image-upload
            @upload="imageUpload"
            :image="form.staffphoto"
          />
        </el-form-item>
 
        <el-form-item label="状态">
          <el-radio-group v-model="form.states">
            <el-radio :label=1>启用</el-radio>
            <el-radio :label=0>禁用</el-radio>
          </el-radio-group>
        </el-form-item>
          <el-form-item label="员工性别">
          <el-radio-group v-model="form.sex">
            <el-radio :label=1>男</el-radio>
            <el-radio :label=2>女</el-radio>
          </el-radio-group>
        </el-form-item>
        
        <el-form-item>
          <el-button
            type="primary"
            @click="save"
          >保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tinymce from "@/components/tinymce";
import ImageUpload from "@/components/imageUpload";
import FileUpload from "@/components/fileUpload";
import { editRequest, getdatilRequest } from "@/api/zrshopstaff";

import { required } from "vuelidate/lib/validators";
import utils from "@/utils/util";
export default {
  name: "Articletypeedit",
  data() {
    return {
      classify: [],
      shopdata: [],
      form: {
        id: 0,
        productid: ""
      },
      productidtext: [],
      educationdata: [],
      constellationdata: [],
      zodiacdata: [],
      typeId: 0,
      shopid: 0
    };
  },
  components: {
    "image-upload": ImageUpload,
    "file-upload": FileUpload,
    tinymce: Tinymce
  },
  created() {
    this.form.id = this.$route.query["id"];
    this.shopid = this.$route.query["shopid"];
    this.getDetail();
  },
  methods: {
    back() {
      this.$router.back();
    },
    imageUpload(url) {
      this.form.staffphoto = url;
    },
    getSelect() {
      getSelectRequest().then(res => {
        console.log(res);
        this.classify = res.data;
      });
    },
    getSelectshop() {
      getSelectshopRequest().then(res => {
        this.shopdata = res.data;
      });
    },
    getDetail() {
      getdatilRequest({
        Id: this.form.id
      }).then(res => {
        if (this.form.id == 0) {
          res.data.shopid = this.shopid;
           res.data.sex=1; 
        }
        this.form = res.data;
      });
    },

    save() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form));
        params.staffphoto = utils.removeUrl(params.staffphoto);
        editRequest(params).then(res => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {
      staffphoto: {
        required
      },
      staffname: {
        required
      },
      staffphone: {
        required
      },

      useraccount: {
        required
      },
      sex: {
        required
      },
      states: {
        required
      }
    
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
